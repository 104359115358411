import {
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import EditIcon from "../icons/EditIcon";
import styled from "styled-components";
import { HuePicker } from "react-color";
import BadgeIcon from "../icons/BadgeIcon";

const ImageAndBadge = ({
  handleTypographyClick,
  handleFileChange,
  series,
  editingBadge,
  badgeColor,
  changeBadgeColor,
  updateBadgeInfo,
  removeBadgeInfo,
  setEditingBadge,
  fileInputRef,
}) => {
  return (
    <Grid sm={6} item>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
            Image
          </Typography>

          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 600,
              color: "#6466F1",
              display: "flex",
              alignItems: "center",
              gap: "4px",
              cursor: "pointer",
            }}
            onClick={handleTypographyClick}
          >
            <EditIcon />
            Change Image
          </Typography>
        </Box>

        <CoverImageHolder>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <CoverImage src={series?.cover} alt={series?.title} />
          {series?.badgeText && series?.badgeColor && !editingBadge && (
            <div
              style={{
                backgroundColor: series?.badgeColor,
                fontSize: "16px",
                width: "100px",
                padding: "10px 10px",
                position: "absolute",
                bottom: "80px",
                left: 0,
                right: 0,
                margin: "auto",
                textAlign: "center",
                borderRadius: "40px",
              }}
            >
              {series?.badgeText}
            </div>
          )}

          {(() => {
            if (editingBadge) {
              return (
                <Box sx={{ w: "100%", mt: 2 }}>
                  <FormLabels>Badge Name</FormLabels>
                  <TextField
                    margin="dense"
                    id="badge"
                    type="text"
                    defaultValue={series.badge}
                    sx={{
                      height: "40px",
                      width: "100%",
                    }}
                    className="inputFormControl"
                  />

                  <Box mt={1} pl={1}>
                    <HuePicker
                      color={badgeColor}
                      onChange={changeBadgeColor}
                      width="100%"
                    />
                  </Box>

                  <Box gap={1} display="flex" mt={2} justifyContent="end">
                    <Typography
                      onClick={() => updateBadgeInfo()}
                      color={"#6466F1"}
                      fontWeight={600}
                      sx={{ cursor: "pointer" }}
                    >
                      Save
                    </Typography>

                    <Typography
                      fontWeight={600}
                      color="#FF0000"
                      onClick={() => removeBadgeInfo()}
                      sx={{ cursor: "pointer" }}
                    >
                      Remove
                    </Typography>
                  </Box>
                </Box>
              );
            }

            return (
              <Tooltip title="Update Badge Info" placement="right">
                <Button
                  variant="contained"
                  onClick={() => setEditingBadge(true)}
                  sx={{
                    borderRadius: "5px",
                    mt: 2,
                    width: "100%",
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <BadgeIcon />
                  <Typography fontSize={"12px"} fontWeight={500}>
                    Add Badge
                  </Typography>
                </Button>
              </Tooltip>
            );
          })()}
        </CoverImageHolder>
      </Box>
    </Grid>
  );
};

export default ImageAndBadge;

const CoverImageHolder = styled.div`
  position: relative;
  width: 100%;
`;

const CoverImage = styled.img`
  width: 100%;
  height: 400px;
  object-fit: contain;
  border-radius: 10px;
  margin-top: 8px;
`;

const FormLabels = styled.div`
  font-size: 12px;
  font-weight: 400;
`;
