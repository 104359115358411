import { Box, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import Loader from "../components/Loader";
import ImageAndBadge from "../components/SeriesDetails/ImageAndBadge";
import Information from "../components/SeriesDetails/Information";
import SeriesVariationDetails from "../components/SeriesVariationDetails";
import SeriesIcon from "../components/icons/SeriesIcon";
import useEditSeries from "../customHooks/useEditSeries";

const EditSeries = () => {
  const [refreshExperiment, setRefreshExperiment] = useState({});
  const [fileName, setFileName] = useState("");
  const [preventDrag, setPreventDrag] = useState(false);

  const fileInputRef = useRef(null);
  const trailerRef = useRef(null);

  const handleTypographyClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleTrailerClick = () => {
    if (trailerRef.current) {
      trailerRef.current.click();
    }
  };

  const handleFileChange = (evt) => {
    updateCoverImage(evt);
  };

  const {
    id,
    fetchSeriesDetails,
    series,
    fetchStats,
    videoStats,
    fetchExperiment,
    updateVariation,
    updateSeriesTitle,
    updateRotation,
    refresh,
    updateCoverImage,
    updateBadgeInfo,
    removeBadgeInfo,
    changeBadgeColor,
    badgeColor,
    handleDeleteConfirmation,
    setDataTypeToDelete,
    setDataToDelete,
    deleteConfirmation,
    setDeleteConfirmation,
    toggleCompareAnalyticsModal,
    isCompareAnalyticsModalOpen,
    isCompareAnalyticsVariantId,
    seriesTrailerLoading,
    deleteConfirmationMessage,
    editingBadge,
    setSeriesTrailerLoading,
    setRefresh,
    setEditingBadge,
    setSeries,
    setIsCompareAnalyticsModalOpen,
    handleDataDelete,
    setDeleteConfirmationMessage,
  } = useEditSeries();

  const checkPermission = (value) => {
    const hasPermissions = localStorage.getItem("permissions");
    if (!hasPermissions) return false;

    if (hasPermissions.includes(value)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (id) {
      fetchSeriesDetails();
      fetchStats();
    }
  }, [id, refresh]);

  useEffect(() => {
    if (series) {
      fetchExperiment();
    }
  }, [series, refreshExperiment]);

  if (!series) {
    return <Loader />;
  }

  return (
    <Layout pageTitle={"Series > Edit Series"} icon={<SeriesIcon />}>
      <Box
        sx={{
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Grid spacing={8} container>
          <Information
            checkPermission={checkPermission}
            series={series}
            updateSeriesTitle={updateSeriesTitle}
            updateRotation={updateRotation}
            handleDeleteConfirmation={handleDeleteConfirmation}
            seriesTrailerLoading={seriesTrailerLoading}
            handleTrailerClick={handleTrailerClick}
            setSeriesTrailerLoading={setSeriesTrailerLoading}
            setRefresh={setRefresh}
            fileName={fileName}
          />
          <ImageAndBadge
            handleTypographyClick={handleTypographyClick}
            handleFileChange={handleFileChange}
            series={series}
            editingBadge={editingBadge}
            badgeColor={badgeColor}
            changeBadgeColor={changeBadgeColor}
            updateBadgeInfo={updateBadgeInfo}
            setEditingBadge={setEditingBadge}
            removeBadgeInfo={removeBadgeInfo}
            fileInputRef={fileInputRef}
          />
        </Grid>

        <Box>
          <SeriesVariationDetails
            setSeries={setSeries}
            series={series}
            updateVariation={updateVariation}
            setRefresh={setRefresh}
            preventDrag={preventDrag}
            setPreventDrag={setPreventDrag}
            videoStats={videoStats}
            toggleCompareAnalyticsModal={toggleCompareAnalyticsModal}
            isCompareAnalyticsModalOpen={isCompareAnalyticsModalOpen}
            setIsCompareAnalyticsModalOpen={setIsCompareAnalyticsModalOpen}
            isCompareAnalyticsVariantId={isCompareAnalyticsVariantId}
            checkPermission={checkPermission}
            handleDataDelete={handleDataDelete}
            setDataToDelete={setDataToDelete}
            setDataTypeToDelete={setDataTypeToDelete}
            deleteConfirmation={deleteConfirmation}
            deleteConfirmationMessage={deleteConfirmationMessage}
            setDeleteConfirmation={setDeleteConfirmation}
            setDeleteConfirmationMessage={setDeleteConfirmationMessage}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default EditSeries;
